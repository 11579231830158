<template>
  <div style="width: 100%">
    <el-form
      ref="postForm"
      label-position="right"
      label-width="110px"
      :model="postData"
      :rules="rules"
      size="medium"
      @submit.native.prevent="handleSubmit">
      <el-form-item prop="templateName" label="模版标题">
        <el-input v-model="postData.templateName" :disabled="isView"></el-input>
      </el-form-item>

      <el-tabs v-model="currentTab" v-if="postData.templateType == 1">
        <el-tab-pane label="科目一" name="sub1">
          <el-table size="small" :data="postData.templateCode.sub1">
            <el-table-column prop="vehicleType" label="类型" width="100"></el-table-column>
            <el-table-column prop="income" label="收入金额(元)">
              <template slot-scope="scope">
                <el-input @keyup.native="handleInput" :disabled="isView" size="small" v-model="scope.row.income"></el-input>
              </template>
            </el-table-column>
            <el-table-column prop="compensation" label="补偿金额(元)">
              <template slot-scope="scope">
                <el-input
                  @keyup.native="handleInput"
                  :disabled="isView"
                  size="small"
                  v-model="scope.row.compensation"></el-input>
              </template>
            </el-table-column>
          </el-table>
        </el-tab-pane>
        <el-tab-pane label="科目二" name="sub2">
          <el-table size="small" :data="postData.templateCode.sub2">
            <el-table-column prop="vehicleType" label="类型" width="100"></el-table-column>
            <el-table-column prop="income" label="收入金额(元)">
              <template slot-scope="scope">
                <el-input @keyup.native="handleInput" :disabled="isView" size="small" v-model="scope.row.income"></el-input>
              </template>
            </el-table-column>
            <el-table-column prop="compensation" label="补偿金额(元)">
              <template slot-scope="scope">
                <el-input
                  @keyup.native="handleInput"
                  :disabled="isView"
                  size="small"
                  v-model="scope.row.compensation"></el-input>
              </template>
            </el-table-column>
          </el-table>
        </el-tab-pane>
        <el-tab-pane label="科目三" name="sub3">
          <el-table size="small" :data="postData.templateCode.sub3">
            <el-table-column prop="vehicleType" label="类型" width="100"></el-table-column>
            <el-table-column prop="income" label="收入金额(元)">
              <template slot-scope="scope">
                <el-input @keyup.native="handleInput" :disabled="isView" size="small" v-model="scope.row.income"></el-input>
              </template>
            </el-table-column>
            <el-table-column prop="compensation" label="补偿金额(元)">
              <template slot-scope="scope">
                <el-input
                  @keyup.native="handleInput"
                  :disabled="isView"
                  size="small"
                  v-model="scope.row.compensation"></el-input>
              </template>
            </el-table-column>
          </el-table>
        </el-tab-pane>
        <el-tab-pane label="其他设置" name="other">
          <el-table size="small" :data="postData.templateCode.other">
            <el-table-column prop="label" label="名称"></el-table-column>
            <el-table-column prop="value" label="设置">
              <template slot-scope="scope">
                <el-input
                  :disabled="isView"
                  size="small"
                  v-model="scope.row.value"
                  @keyup.native="handleInput"
                  v-if="scope.row.key != 'expiration' && scope.row.key != 'returnType'"></el-input>
                <el-select
                  :disabled="isView"
                  placeholder="请选择"
                  v-model="scope.row.value"
                  v-if="scope.row.key == 'expiration'"
                  style="width: 100%"
                  size="small">
                  <el-option value="12" label="12个月"></el-option>
                  <el-option value="24" label="24个月"></el-option>
                  <el-option value="36" label="36个月"></el-option>
                </el-select>
                <el-select
                  :disabled="isView"
                  placeholder="请选择"
                  v-model="scope.row.value"
                  v-if="scope.row.key == 'returnType'"
                  style="width: 100%"
                  size="small">
                  <el-option :value="1" label="否"></el-option>
                  <el-option :value="2" label="是"></el-option>
                </el-select>
              </template>
            </el-table-column>
          </el-table>
        </el-tab-pane>
      </el-tabs>

      <div class="admin-dialog-btns" style="margin-top: 20px">
        <el-button type="primary" native-type="submit" :loading="loading" v-if="!isView">提交</el-button>
        <el-button
          @click="
            () => {
              this.$emit('end');
            }
          "
          >取消
        </el-button>
      </div>
    </el-form>
  </div>
</template>

<script>
import {expenseTemplateAdd} from '@/api/index.js';
export default {
  props: {
    row: Object,
  },
  data() {
    return {
      rules: {
        templateName: [this.$rules.required],
        templateType: [this.$rules.required],
      },
      currentTab: 'sub1',
      isView: !!this.$props.row,
      loading: false,
      postData: {
        templateName: '',
        templateType: 1,
        templateCode: {
          sub1: [
            {
              vehicleType: 'A1',
              income: '',
              compensation: '',
            },
            {
              vehicleType: 'A2',
              income: '',
              compensation: '',
            },
            {
              vehicleType: 'A3',
              income: '',
              compensation: '',
            },
            {
              vehicleType: 'B1',
              income: '',
              compensation: '',
            },
            {
              vehicleType: 'B2',
              income: '',
              compensation: '',
            },
            {
              vehicleType: 'C1',
              income: '',
              compensation: '',
            },
            {
              vehicleType: 'C2',
              income: '',
              compensation: '',
            },
          ],
          sub2: [
            {
              vehicleType: 'A1',
              income: '',
              compensation: '',
            },
            {
              vehicleType: 'A2',
              income: '',
              compensation: '',
            },
            {
              vehicleType: 'A3',
              income: '',
              compensation: '',
            },
            {
              vehicleType: 'B1',
              income: '',
              compensation: '',
            },
            {
              vehicleType: 'B2',
              income: '',
              compensation: '',
            },
            {
              vehicleType: 'C1',
              income: '',
              compensation: '',
            },
            {
              vehicleType: 'C2',
              income: '',
              compensation: '',
            },
          ],
          sub3: [
            {
              vehicleType: 'A1',
              income: '',
              compensation: '',
            },
            {
              vehicleType: 'A2',
              income: '',
              compensation: '',
            },
            {
              vehicleType: 'A3',
              income: '',
              compensation: '',
            },
            {
              vehicleType: 'B1',
              income: '',
              compensation: '',
            },
            {
              vehicleType: 'B2',
              income: '',
              compensation: '',
            },
            {
              vehicleType: 'C1',
              income: '',
              compensation: '',
            },
            {
              vehicleType: 'C2',
              income: '',
              compensation: '',
            },
          ],
          other: [
            {label: '科目一次数上限', value: '', key: 'sub1Limit'},
            {label: '科目二次数上限', value: '', key: 'sub2Limit'},
            {label: '科目三次数上限', value: '', key: 'sub3Limit'},
            {label: '补偿总额限制(元)', value: '', key: 'totalLimit'},
            {label: '保单有效期', value: '', key: 'expiration'},
            {label: '是否退费', value: '', key: 'returnType'},
          ],
        },
      },
    };
  },
  created() {
    let d = {
      sub1Limit: '科目一次数上限',
      sub2Limit: '科目二次数上限',
      sub3Limit: '科目三次数上限',
      totalLimit: '补偿总额限制(元)',
      expiration: '保单有效期',
      returnType: '是否退费',
    };
    if (this.$props.row) {
      let postData = JSON.parse(JSON.stringify(this.$props.row));
      if (postData.templateType == 1) {
        // 补考费会员服务处理
        postData.templateCode = JSON.parse(postData.templateCode);
        postData.templateCode.other = Object.keys(postData.templateCode.other).map((key) => {
          return {key: key, label: d[key], value: postData.templateCode.other[key]};
        });
        this.postData = postData;
      }
    }
  },
  methods: {
    handleInput(e) {
      e.target.value = e.target.value.match(/^\d*/g)[0] || null;
    },

    hasError(sub) {
      return sub.find((item) => {
        // this.$set(item, 'compensationErr', false);
        // this.$set(item, 'incomeErr', false);
        if (item.income && !item.compensation) {
          // item.compensationErr = true;
          return true;
        }
        if (!item.income && item.compensation) {
          // item.incomeErr = true;
          return true;
        }
      });
    },

    hasEmpty(arr) {
      return arr.find((item) => {
        if (!item.value) {
          return true;
        }
      });
    },

    handleSubmit() {
      this.$refs['postForm'].validate((valid) => {
        if (valid) {
          if (this.hasError(this.postData.templateCode.sub1) && this.postData.templateType == 1) {
            this.currentTab = 'sub1';
            this.$message.warning('请填写完整信息');
            return false;
          }
          if (this.hasError(this.postData.templateCode.sub2) && this.postData.templateType == 1) {
            this.currentTab = 'sub2';
            this.$message.warning('请填写完整信息');
            return false;
          }
          if (this.hasError(this.postData.templateCode.sub3) && this.postData.templateType == 1) {
            this.currentTab = 'sub3';
            this.$message.warning('请填写完整信息');
            return false;
          }
          if (this.hasEmpty(this.postData.templateCode.other) && this.postData.templateType == 1) {
            this.currentTab = 'other';
            this.$message.warning('请填写完整信息');
            return false;
          }

          let postData = {};
          if (this.postData.templateType == 1) {
            postData = JSON.parse(JSON.stringify(this.postData));
            let d = {};
            postData.templateCode.other.map((item) => {
              d[item.key] = item.value;
            });
            postData.templateCode.other = d;
            postData.templateCode = JSON.stringify(postData.templateCode);
          }
          this.loading = true;
          this.$utils.resolveRequest(expenseTemplateAdd(postData), this);
        } else {
          this.loading = false;
          this.$message.warning('请填写正确信息！');
          return false;
        }
      });
    },
  },
};
</script>

<style></style>
